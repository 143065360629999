export class UserPermission {
    
    constructor(){
        this.IdEmpresa=0;
        this.IdOrganizacion=0;
        this.IdUsuario="";
    }
    IdEmpresa: number;
    IdOrganizacion: number;
    IdUsuario: string;
    Ruts: string[] = [];
    Pi: boolean = false;
    AdminCartera: boolean = false;
    
}
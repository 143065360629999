import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'ingreso', loadChildren: () => import('./monitor/catalogo/catalogo.module').then(m => m.CatalogoModule) },
  { path: '', loadChildren: () => import('./monitor/catalogo/catalogo.module').then(m => m.CatalogoModule) },
  { path: 'catalogo', loadChildren: () => import('./monitor/catalogo/catalogo.module').then(m => m.CatalogoModule) },
  { path: 'obras/:tipoObra/:etapaObra/:rubroObra', loadChildren: () => import('./monitor/obras/obras.module').then(m => m.ObrasModule) },
  { path: 'detalle/:idObra/:tipoPlantilla', loadChildren: () => import('./monitor/detalle/detalle.module').then(m => m.DetalleObraModule) },
  { path: 'ficha-empresa/:idEmpresa/:idPanel', loadChildren: () => import('./monitor/ficha-empresa/ficha-empresa.module').then(m => m.FichaEmpresaModule) },
  { path: '**', redirectTo: '' }  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { CookieService } from "ngx-cookie-service";
import { map } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { Observable, throwError } from "rxjs";
import { interfaces } from "src/app/_interfaces/interfaces";
import { UserPermission } from "../_models/UserPermission.model";
import { JwtHelperService } from "@auth0/angular-jwt";

export interface LoginResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
    userName: string;
    ".issued": string;
    ".expires": string;
}

export interface ISecurityUser {
    userName: string;
}

@Injectable({
    providedIn: 'root'
})
export class ServicioAutenticacion {

    static tokenKey = "icMarketPlaceToken";
    static portalKey = "icMarketPlacePortalKey";
    static suscripcionKey = "icMarketPlaceSuscripcionKey";
    static currentUser = "icCurrentUser";


    constructor(private cookieService: CookieService, private http: HttpClient, private jwtHelper: JwtHelperService) {
    }

    public estaAutenticado(): boolean {
        return this.currentIdTokenValue != null;
    }

    public get currentAccessTokenValue(): string {
        return this.cookieService.get('accessToken');
    }

    public get currentIdTokenValue(): string {
        return this.cookieService.get('idToken');
    }

    public get currentPermissionValue(): string {
        return this.cookieService.get('tokenPermisos');
    }

    public get currentRefreshTokenValue(): string {
        return this.cookieService.get('refreshToken');
    }


    public obtenerPortal(): string | null {
        return sessionStorage.getItem(ServicioAutenticacion.portalKey);
    }

    public obtenerIdSuscripcion(): string | null {
        return sessionStorage.getItem(ServicioAutenticacion.suscripcionKey);
    }

    public obtenerCurrentUser(): interfaces.UsuarioActual | null {
        return JSON.parse(sessionStorage.getItem(ServicioAutenticacion.currentUser) || "{}");
    }

    public getCurrentUserPermissionValue(): UserPermission {
        
        if (this.cookieService.check('tokenPermisos')) {
          let suscriptionTokenPermisos = JSON.stringify(this.jwtHelper.decodeToken(this.currentPermissionValue) || {});
          let suscriptionObj = <any>JSON.parse(suscriptionTokenPermisos);
          let enterpriseSelected = <any>JSON.parse(atob(suscriptionObj.permisos));
    
          const userPermission: UserPermission = new UserPermission();
    
          userPermission.IdEmpresa = enterpriseSelected.IdEmpresa;
          userPermission.IdOrganizacion = enterpriseSelected.IdOrganizacion;
          userPermission.IdUsuario = enterpriseSelected.IdUsuario;
          userPermission.Pi = enterpriseSelected.Pi;
          userPermission.Ruts = enterpriseSelected.Ruts;
          userPermission.AdminCartera = enterpriseSelected.AdminCartera;  
          return userPermission;
    
        }
        return new UserPermission();
      }

    public salir() {
        sessionStorage.removeItem(ServicioAutenticacion.tokenKey);
        sessionStorage.removeItem(ServicioAutenticacion.portalKey);
        sessionStorage.removeItem(ServicioAutenticacion.suscripcionKey);
        sessionStorage.removeItem(ServicioAutenticacion.currentUser);
    }


    private procesarError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

    private refreshTokenTimeout: any;
    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.currentAccessTokenValue.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    start() {
        this.startRefreshTokenTimer();
    }

    refreshToken(): Observable<void> {
        return this.http.get<any>(`${environment.urlApiSSO}/api/v1/autenticacion/refrescartoken?token=${this.currentRefreshTokenValue}`)
            .pipe(map(data => {
                if (data && data.accessToken != "") {
                    this.saveAccessToken(data.accessToken);
                } else {
                    this.logout();
                }
            }));
    }

    logout() {
        this.stopRefreshTokenTimer();
        // this.logoutUser();
        // this.logoutSuscripcion();
        // this.logoutPortal();
    }

    saveAccessToken(accessToken: string) {
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const d = new Date(0);
        d.setUTCSeconds(jwtToken.exp);
        this.cookieService.set('accessToken', accessToken, 0, '/', 'iconstruye.cl', false, 'Lax');
    }

    public expiredToken(): boolean {
        if (this.currentAccessTokenValue) {
            const token = this.currentAccessTokenValue;
            const tokenJson = JSON.parse(atob(token.split(".")[1]));
            const fecha = tokenJson.exp;
            return Math.round(Date.now() / 1000) >= fecha;
        } else {
            return true;
        }

    }

    saveIdToken(idToken: string) {
        const jwtToken = JSON.parse(atob(idToken.split('.')[1]));
        const d = new Date(0);
        d.setUTCSeconds(jwtToken.exp);
        this.cookieService.set('currentUser', idToken, d, '/', 'iconstruye.cl', false, 'Lax');
    }

}

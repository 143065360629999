import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServicioAutenticacion } from '../_services/autenticacion.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: ServicioAutenticacion) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentAccessToken = this.authenticationService.currentAccessTokenValue;
    const currentPermission = this.authenticationService.currentPermissionValue;
    const currentIdOrg = this.authenticationService.getCurrentUserPermissionValue().IdOrganizacion
    // const currentUser = this.authenticationService.getcurrentUsernameValueFromCookie();
    // const basicUser = environment.ApiBasicUser;
    // const basicPass = environment.ApiBasicPassword;
    // const basicEncoded = btoa(basicUser + ":" + basicPass);
    // const useBasic = environment.UseBasic && request.url.indexOf("mktp-compra") > -1;
    if (currentAccessToken && currentPermission) {
      if (request.url.includes("apimarketplace")) {
        let idSuscripcion = this.authenticationService.getCurrentUserPermissionValue().IdEmpresa.toString();
        let idPortal = 'Ax8=';
        request = request.clone({
          headers: new HttpHeaders({
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${currentAccessToken}`,
            'IdSuscripcion': idSuscripcion,
            'IdPortal': idPortal
          })
        });
      } else {
        if (environment.apiLocal && !request.url.includes('gateway')) {
          request = request.clone({
            headers: new HttpHeaders({
              'Content-Type': `application/json`,
              'Authorization': `Bearer ${currentAccessToken}`,
              'X-IdEmpresa': `${this.authenticationService.getCurrentUserPermissionValue().IdEmpresa}`,
              'X-IdOrg': `${this.authenticationService.getCurrentUserPermissionValue().IdOrganizacion}`,
              'X-IdUsuario': `${this.authenticationService.getCurrentUserPermissionValue().IdUsuario}`
            })
          });
        } else {
          request = request.clone({
            headers: new HttpHeaders({
              'Content-Type': `application/json`,
              'Authorization': `Bearer ${currentAccessToken}`,
              'X-Permission': currentPermission,
              'IdSuscripcion': "FEO",
            })
          });
        }
      }

    }
    return next.handle(request);
  }
}

import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class ServicioUbicacionApi {
    private url = "";
    private urlServicioEmpresa = "";

    constructor() {
        this.url = environment.url;
        this.urlServicioEmpresa = environment.urlEmpresa;
    }

    public getUrl(): string {
        return this.url;
    }

    public getApiUrl(version: number): string {
        return this.url + "api/v" + version + "/";
    }

    public getUrlApiEmpresa(version: number): string{
        return this.urlServicioEmpresa + "api/v" + version + "/";
    }

}


import { Injectable } from "@angular/core";
import { ServicioUbicacionApi } from "./ubicacion.service";
import { ServicioAutenticacion } from "./autenticacion.service";
import { Observable, throwError } from "rxjs";
//import { _throw } from "rxjs/Observable/throw";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class ServicioGateway {

    constructor(private servicioAutenticacion: ServicioAutenticacion,
        private ubicacionServidorService: ServicioUbicacionApi,
        private http: HttpClient) { }

    public postAnonimo<T>(url: string, data: any): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };

        return this.http.post<any>(this.ubicacionServidorService.getApiUrl(1) + url, data, httpOptions)
            .pipe(map(r => r));
    }

    public get<T>(url: string): Observable<T> {

        const token = this.servicioAutenticacion.currentAccessTokenValue;
        const tokenPermisos = this.servicioAutenticacion.currentPermissionValue;
        const idSuscripcion = this.servicioAutenticacion.obtenerIdSuscripcion();

        // if (token == null) {
        //     return Observable.fromPromise(Promise.reject("Usuario no logueado"));
        // } else {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                "X-Permission": tokenPermisos,
                "IdSuscripcion": "FEO"
            })
        };
        return this.http.get<any>(this.ubicacionServidorService.getApiUrl(1) + url, httpOptions).
            pipe(map((r) => r));
        //}
    }

    public post<T>(url: string, data: any): Observable<T> {

        const token = this.servicioAutenticacion.currentAccessTokenValue;
        const tokenPermisos = this.servicioAutenticacion.currentPermissionValue;
        const idSuscripcion = this.servicioAutenticacion.obtenerIdSuscripcion();

        // if (token == null) {
        //     return Observable.fromPromise(Promise.reject("Usuario no logueado"));
        // } else {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                "X-Permission": tokenPermisos,
                "IdSuscripcion": "FEO"
            })
        };
        return this.http.post<any>(this.ubicacionServidorService.getApiUrl(1) + url, data, httpOptions).
            pipe(map((r) => r));
        //}

    }

    private controlError = (error: Response | any) => {
        let errMsg: string;
        if (error) {
            if (error.status === 401) {
                this.servicioAutenticacion.refreshToken();
                return throwError("Ha ocurrido un error, por favor intentar nuevamente");
            } else {
                const body = error.json() || "";
                const err = body || JSON.stringify(body);
                errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
            }
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return errMsg;
    }
}

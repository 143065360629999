import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
// import { ApplicationInsightsErrorHandler } from './_services/application-insights.service';
import { ServicioAutenticacion } from './_services/autenticacion.service';
import { EmpresaService } from './_services/empresa.service';
import { ServicioGateway } from './_services/gateway.service';
import { HelperService } from './_services/helper.service';
import { MonitorService } from './_services/monitor.service';
import { SessionService } from './_services/session.service';
import { ServicioUbicacionApi } from './_services/ubicacion.service';
import { Wrapper } from './_services/wrapper.service';
import { ErrorInterceptor } from './_interceptors/errorhttp.interceptor';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    SessionService,
    ServicioAutenticacion,
    ServicioUbicacionApi,
    HelperService,
    ServicioGateway,
    EmpresaService,
    MonitorService,
    CookieService,
    Wrapper,
    { provide: 'googleTagManagerId', useValue: environment.gTagManagerCode },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from "@angular/core";
import { ServicioGateway } from "./gateway.service";
import { EmpresaService } from "./empresa.service";
import { Observable, map } from "rxjs";
import { UsuarioConectadoRespuesta, Usuario } from "./usuario";
import * as XLSX from "xlsx"; //import * as XLSX from "xlsx";

type AOA = any[][];

export module EnumHelper {
    export enum TiposMime {
        xls = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        pdf = "application/pdf"
    }
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {

    constructor(private gatewayService: ServicioGateway, private empresaService: EmpresaService) { }

    private data: AOA = [ [1, 2], [3, 4] ];

    public usuarioConectado(): Observable<UsuarioConectadoRespuesta> {
        const url = "Identificacion/UsuarioActual";
        return this.gatewayService.
            get<Mensaje.UsuarioActualRespuesta>(url).
            pipe(map((resp: { Exitosa: any; MensajeError: any; Usuario: Dto.Usuario; }) => {
                return <UsuarioConectadoRespuesta>{
                    Exitosa: resp.Exitosa,
                    MensajeError: resp.MensajeError,
                    Usuario: new Usuario(resp.Usuario)
                };
            }));
    }

    public parseJwt(token: string): any {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');

      return JSON.parse(window.atob(base64));
  }

    public empresaUsuarioConectado(): Observable<Mensaje.ObtenerEmpresaRespuesta> {
        return this.empresaService.obtenerEmpresaUsuario();
    }

    private base64AArrayBuffer = (base64: string) => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public descargarArchivo = (resp: any, mime: string, nombre: string) => {
        const array = this.base64AArrayBuffer(resp);

        var blob = new Blob([array], { type: mime });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, nombre);
        }
        else {
            var link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', nombre);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    public obtenerJsonExcel = (callback: any) => {
        const upload = document.createElement('input');
        upload.setAttribute("type","file");
        upload.addEventListener("change", (event: any) => {
            const target: DataTransfer = <DataTransfer>(event.target);

            if(target.files.length == 1){
                const reader: FileReader = new FileReader();
                reader.onload = (e: any) =>{
                    //lectura
                    const file: string = e.target.result;
                    const libro: XLSX.WorkBook = XLSX.read(file, {type: "binary"});

                    //Obtiene nombre y datos de la hoja excel
                    const hojaNombre: string = libro.SheetNames[0];
                    const hoja: XLSX.WorkSheet = libro.Sheets[hojaNombre];

                    //convierte a json
                    let data = <AOA>XLSX.utils.sheet_to_json(hoja, { header: 1, defval: null });

                    callback(data);
                };

                reader.readAsBinaryString(target.files[0]);
            }
         });
        upload.click();
    }
}

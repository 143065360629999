import { Injectable } from "@angular/core";
import { ServicioGateway } from "./gateway.service";
import { Observable } from "rxjs";

@Injectable()
export class SessionService { //extends Map<string, any> {

    private keys: any = {};
    constructor() {

    }

    public reset() {
        //recorrer keys
        //sessionStorage.clear();
    }

    public set(id: string, value: any): this {
        this.keys[id] = id;
        if (typeof value === 'object') value = JSON.stringify(value);
        sessionStorage.setItem(id, value);
        return this;
    }

    public get(id: string): any | undefined {
        const value = sessionStorage.getItem(id);
        try {
            return value ? JSON.parse(value) : undefined;
        } catch (e) {
            return value;
        }
    }

}
